import { Stack ,Typography} from '@mui/material';
import {Link} from 'react-router-dom';

import {logo} from '../utils/constants';
import SearchBar from './SearchBar';
const Navbar = () => 
   (
    <Stack 
    direction='row'
    alignContent='center'
    p={2} 
    sx={{position:'sticky',background:'#000',top:0,justifyContent: 'space-between',zIndex:1000}}>
        <Link to="/" style={{display:'flex',alignItems:'center'}}>
            <img src={logo} alt='logo' height={45} />
        <Typography variant='h5'
        color='#fff'
        fontFamily="Inter"
        fontWeight="bold"
        >Le<span style={{color:'red'}}>
          Tube          
          </span>
          </Typography>
        </Link>
        <SearchBar />
    </Stack>
  )


export default Navbar